export default {
  getLimitedSizeStatus(group) {
    const sizeCount = group.size_list ? group.size_list.length : group.size_count;
    if (!sizeCount) return;
    return (group.available_size_list.length / Number(sizeCount)) * 100 < 25;
  },
  getPercentOff(product) {
    const regular = product.regular_retail || product.retail_price_list[0].regular;
    const current = product.retail || product.retail_price_list[0].current;
    let percentOff = Number(((regular - current) / regular) * 100).toFixed(0);
    if (percentOff > 0) return percentOff;
    return;
  },
  findProduct(productsData, {
    slug, style, color, gender, size, categories, catalogs, query
  }) {
    if (!productsData) return;
    const found = productsData.find(productData => {
      const product = productData.product;
      if (product.slug === slug) {
        if (product.style_list && product.style_list[0] && product.style_list[0].data_style && style) {
          return product.style_list[0].data_style.toLowerCase() === style.toLowerCase();
        } else {
          if (product.product_category !== 'footbeds') {
            if (product.gender) return product.gender === gender;
            return true;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    });
    return found; 
  }
}