import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';

if (typeof process === 'undefined' || !process || !process.env || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

const router = new VueRouter({
  mode: 'history',
  base: `/`,
  pathToRegexpOptions: { strict: true },
  routes
})

export default router;