export default {
  namespaced: true,
  state: () => ({
    catalog_cfg: window.catalog_cfg,
    settings: window.settings,
    product_sort: window.catalog_cfg.product_sort
  }),
  getters: {
    tabs(state) {
      if (!state.catalogConfig || !state.catalogConfig.homepage_cfg) return;
      return state.catalogConfig.homepage_cfg.tabs;
    }
  }
}