const getters = {
  regularStockStatus(state, getters) {
    let status;
    const sku = getters.getSkuObject;
    if (
      (sku.webstore_available === 0 || sku.webstore_ats >= 0)
      && sku.confirmed_eta_days
      && sku.confirmed_eta_days > 0
      && sku.confirmed_eta_days <= 5
    ) {
      status = 'eta';
    } else {
      if (!getters.isAvailable && getters.isComingSoon) status = 'coming_soon';
      if (!getters.isAvailable && !getters.isComingSoon) status = 'out_of_stock';
      if (sku.discontinued && Number(sku.discontinued) > 0) status = 'discontinued';
      if (sku.webstore_ats >= 1) status = 'in_stock';
      if (sku.webstore_ats >= 1 && sku.webstore_ats <= 5) status = 'limited_stock'
    }
    return status;
  },
  isCurrent(state, getters) {
    return getters.getSkuObject?.catalog_tags?.includes("webstoreCurrent");
  },
  isCurrentATS(state, getters) {
    const sku = getters.getSkuObject;
    if (!sku || !getters.isCurrent) return;
    return sku.webstore_available && sku.webstore_ats;
  },
  isETAtoATS(state, getters) {
    const sku = getters.getSkuObject;
    if (!sku || !getters.isCurrent) return;
    return (
      (sku.webstore_available === 0 || sku.webstore_ats >= 0) &&
      sku.confirmed_eta_days &&
      sku.confirmed_eta_days > 0 &&
      sku.confirmed_eta_days <= 5
    );
  }
}

export {
  getters
};