import isEmpty from "lodash/isEmpty";

const sort = {
  sortByPriceLowToHigh(a, b) {
    const aMin = Math.min(a.retail_price_list[0].current, a.retail_price_list[0].regular);
    const bMin = Math.min(b.retail_price_list[0].current, b.retail_price_list[0].regular);
    return (aMin - bMin);
  },
  sortByPriceHighToLow(a, b) {
    return sort.sortByPriceLowToHigh(a, b) * -1;
  },
  sortByBestSelling(a, b, { country, state, isRegionAvailable, isCountryAvailable }) {
    if (isCountryAvailable && isRegionAvailable) {
      const combine = `${country}-${state.replace(/\s/g, '')}`.toLowerCase();
      const bRegion = b.unit_sales_by_region[combine] ? b.unit_sales_by_region[combine] : 0;
      const aRegion = a.unit_sales_by_region[combine] ? a.unit_sales_by_region[combine] : 0;
      return Number(bRegion) - Number(aRegion);
    }
    if (isCountryAvailable) {
      const bCountry = b.unit_sales_by_region[country] ? b.unit_sales_by_region[country] : 0;
      const aCountry = a.unit_sales_by_region[country] ? a.unit_sales_by_region[country] : 0;
      return Number(bCountry) - Number(aCountry);
    }
    return Number(b.units_sales) - Number(a.units_sales);
  },
  sortByProductSort(products, product_sort) {
    function _sortByProductSort(a, b) {
      function getSortNumber(group) {
        const A_BIG_NUMBER = 9999999;
        // Get the first sorting number
        let firstNumber;
        let productSortCatalog;
        group.catalog_tags.forEach(catalog => {
          if (!product_sort[catalog] || isEmpty(product_sort[catalog])) return;
          // if (group.slug === 'costa-flip') debugger;
          if (!firstNumber || (product_sort[catalog]?.sort && Number(product_sort[catalog].sort) > firstNumber)) {
            firstNumber = Number(product_sort[catalog].sort);
            productSortCatalog = catalog;
          }
        });
        // Get the 2nd part of sorting number
        let secondNumber;
        if (!productSortCatalog) productSortCatalog = 'default';
        if (product_sort[productSortCatalog]?.slug?.[group.slug]) {
          secondNumber = Number(product_sort[productSortCatalog].slug[group.slug])
        }
        return {
          firstNumber: firstNumber || A_BIG_NUMBER,
          secondNumber: secondNumber || A_BIG_NUMBER
        };
      }
      let sortGroupA = getSortNumber(a);
      let sortGroupB = getSortNumber(b);
      return sortGroupA.firstNumber - sortGroupB.firstNumber || sortGroupA.secondNumber - sortGroupB.secondNumber;
    }
    return products.sort(_sortByProductSort);
  }
}

export default {
  ...sort
}