const getters = {
  // Reserve support
  isReservePreorderURL(state, getters) {
    if (!state.options.catalogs) return false;
    if (!getters.product.catalog_tags.find(catalog_tag => catalog_tag === 'webstoreReserve')) return;
    if (state.options.catalogs) return state.options.catalogs.indexOf('reserve') >= 0;
    return state.options.catalogs.indexOf('reserve') >= 0;
  },
  isReserveGroup(state, getters) {
    return getters.getSkuObject?.catalog_tags?.includes('webstoreReserve')
  },
  isReserveSkuOutOfStock(state, getters) {
    return getters.isReserveGroup
      && getters.getSkuObject
      && !getters.getSkuObject.webstore_reserve_available
      && !getters.getSkuObject.webstore_reserve_ats;
  },
  isReserveSkuInStock(state, getters) {
    return getters.isReserveGroup
      && getters.getSkuObject
      && getters.getSkuObject.webstore_reserve_available
      && getters.getSkuObject.webstore_reserve_ats > 0;
  },
  isReserveSkuLimitedInStock(state, getters) {
    return getters.isReserveGroup
      && getters.getSkuObject
      && getters.getSkuObject.webstore_reserve_available
      && getters.getSkuObject.webstore_reserve_ats <= 5
      && getters.getSkuObject.webstore_reserve_ats > 0;
  },
  reserveStockStatus(state, getters) {
    let status = 'out_of_stock';
    if (getters.getSkuObject.production_eta_days && getters.getSkuObject.production_eta_days > 0) {
      if (getters.isReserveSkuInStock) status = 'in_stock';
      if (getters.isReserveSkuLimitedInStock) status = 'limited_stock';
      if (getters.isReserveSkuOutOfStock) status = 'out_of_stock';
    }
    return status;
  },
  isReserveATS(state, getters) {
    return getters.isReserveGroup && (getters.isReserveSkuInStock || getters.isReserveSkuLimitedInStock);
  }
};

export {
  getters
};