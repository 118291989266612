import Vue from "vue";

export default {
  namespaced: true,
  state: () => {
    return {
      // data for reviews PDP
      reviewList: [],
      tagList: window.review_tag_list || [
        {
          tag: "footbed",
          text: "Footbeds"
        },
        {
          tag: "footwear",
          text: "Footwear"
        },
        {
          tag: "pain",
          text: "Pain Relief"
        },
        {
          tag: "comfort",
          text: "Comfort"
        }
      ],
      // data for submit review page
      product_groups: [],
      rating_lists: window.rating_lists,
      sku_info: window.sku_info,
      active_orderline: window.active_orderline,
      reserve_orderlines: window.reserve_orderlines,
      catalog_cfg: window.catalog_cfg,
      isReviewCompleted: window.completed || 0,
      settings: window.settings,
      error: window.error
    };
  },
  actions: {
    addReview({ commit }, payload) {
      if (!payload) return;
      commit("addReview", payload);
    },
    setProductGroups({ commit }, payload) {
      if (!payload) return;
      commit("setProductGroups", payload);
    }
  },
  mutations: {
    addReview(state, payload) {
      if (state.reviews.find((review) => review.id === payload.id)) return;
      state.reviews.push(payload);
    },
    setProductGroups(state, payload) {
      Vue.set(state, "product_groups", payload);
    }
  },
  getters: {
    reviewListLength(state) {
      return state.reviewList.length;
    }
  }
};
