const getters = {
  isProductHaveSizes(state, getters) {
    if (!getters.product) return undefined;
    return getters.product.size_list.length > 0
  },
  // Gender Specific
  /**
   * Check if gender is defined
   * @param { Object } state state of pdp
   * @param { Object } getters getters of pdp
   * @returns { Boolean } true if the product is unisex, didnt specify a gender
   */
  isUnisex(state, getters) {
    return !getters.product.gender || getters.product.gender === "";
  },
  // Footwear Specific
  /**
   * Check if product is supportive sandals
   * @param { Object } state state of pdp
   * @param { Object } getters getters of pdp
   * @returns { Boolean } true if the product is a supportive sandal
   */
  isSupportiveSandals(state, getters) {
    return getters.isFootwear && !!getters.product.collection_list.find(collection => collection.id === 540)
  },
  isOrthopedicSandals(state, getters) {
    return getters.isFootwear && !!getters.product.collection_list.find(collection => collection.id === 541)
  },
  /**
   * Check if footwear is Unisex
   */
  isUnisexFootwear(state, getters) {
    return getters.isFootwear && getters.isUnisex;
  },
  isProductOnSale(state, getters) {
    if (getters.isComingSoon) return false;
    return !!getters.product.retail_on_sale;
  },
  isFootwear(state, getters) {
    return getters.product.product_category === 'footwear';
  },
  isFootbeds(state, getters) {
    return getters.product.product_category === 'footbeds';
  },
  isDistrict(state, getters) {
    return !!getters.product.collection_list.find(collection => Number(collection.id) === 299)
  },
  isBloomAlgae(state, getters) {
    return !!getters.product.feature_list.find(feature => Number(feature.id) === 254);
  },
  isRecycledCork(state, getters) {
    return !!getters.product.feature_list.find(feature => Number(feature.id) === 149);
  },
  isSocks(state, getters) {
    return getters.product.product_category === 'socks'
  },
  isReCORK(state, getters) {
    return getters.product.product_category === 'recork'
  },
  isCO2Labeled(state, getters) {
    return getters.product.co2neg_list && getters.product.co2neg_list.length > 0;
  },
  isCo2Negative(state, getters) {
    return getters.product.co2neg_list && getters.product.co2neg_list.find(co2tag => Number(co2tag.id) === 556)
  },
  isCo2Positive(state, getters) {
    return getters.product.co2neg_list && getters.product.co2neg_list.find(co2tag => Number(co2tag.id) === 557)
  },
  isFinalSale(state, getters) {
    return getters.product.catalog_tags.includes('webstoreDeals') || getters.product.catalog_tags.includes('webstoreClearance') || getters.product.catalog_tags.includes('webstoreFinalSale');
  },
  isMens(state, getters) {
    return getters.product.gender === 'mens'
  }
};

export {
  getters
};