import sizeSelector from "./_sizes-selector";
import url from "./_url";
import arraySupport from "./_array";
import dateSupport from "./_date";
import emailSupport from "./_email";
import functions from "./_functions";
import groupFunctions from "./_groupFunctions";
import stringSupport from "./_string";

let shares = {
  ...sizeSelector,
  ...arraySupport,
  ...url,
  ...dateSupport,
  ...emailSupport,
  ...functions,
  ...groupFunctions,
  ...stringSupport
};

export function mapShares(array) {
  let mappedFunctions = {};
  array.forEach((func) => {
    if (shares[func]) {
      mappedFunctions[func] = shares[func];
    } else {
      throw Error("Function name didn't match", func);
    }
  });
  return mappedFunctions;
}

export default shares;
