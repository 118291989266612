import axios from "./_axiosSetup";

export default class AccountAPI {
  constructor({yoursole_url, region}) {
    this.url = yoursole_url + '/' + region + '/account';
  }
  login(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/login`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  logout(cb, config) {
    axios.post(`${this.url}/logout`, null, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  create(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/create`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  reset(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/reset-password`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  requestPasswordReset(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/request-password-reset`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  verify(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/verify`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
  resendVerificationEmail(data, cb, config) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));
    axios.post(`${this.url}/verify-resend`, form, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then(function(result) {
      if (cb) cb(result);
    });
  }
}