import plpRoutes from './plp.routes';
import pdpRoutes from './pdp.routes';

export default {
  path: `/${window.settings.region}/shop/`,
  name: 'shop',
  component: () => import(/* webpackChunkName: "app-view" */ '~/views/Shop.vue'),
  children: [
    plpRoutes,
    pdpRoutes
  ]
}