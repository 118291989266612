export default {
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  },
  reload(time) {
    setTimeout(function () { location.reload() }, time ? time : 1500);
  },
  highLightContainer(element) {
    element.classList.add("animate__animated");
    element.classList.add("animate__pulse");
    setTimeout(function () {
      element.classList.remove("animate__animated");
      element.classList.remove("animate__pulse");
    }, 2000);
  }
}