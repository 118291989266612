const getters = {
  // MadeForYou support
  isMadeForYouURL(state, getters) {
    if (!state.options.catalogs) return false;
    if (!getters.product.catalog_tags.find(catalog_tag => catalog_tag === 'webstoreMadeForYou')) return;
    if (state.options.catalogs) return state.options.catalogs.indexOf('madeforyou') >= 0;
    return state.options.catalogs.indexOf('madeforyou') >= 0;
  },
  isMadeForYouGroup(state, getters) {
    return getters.getSkuObject?.catalog_tags?.includes('webstoreMadeForYou');
  },
  isMadeForYouSkuOutOfStock(state, getters) {
    return getters.isMadeForYouGroup
      && getters.getSkuObject
      && !getters.getSkuObject.webstore_madeforyou_available
      && !getters.getSkuObject.webstore_madeforyou_ats;
  },
  isMadeForYouSkuInStock(state, getters) {
    return getters.isMadeForYouGroup
      && getters.getSkuObject
      && getters.getSkuObject.webstore_madeforyou_available
      && getters.getSkuObject.webstore_madeforyou_ats > 0;
  },
  isMadeForYouSkuLimitedInStock(state, getters) {
    return getters.isMadeForYouGroup
      && getters.getSkuObject
      && getters.getSkuObject.webstore_madeforyou_available
      && getters.getSkuObject.webstore_madeforyou_ats <= 5
      && getters.getSkuObject.webstore_madeforyou_ats > 0;
  },
  madeForYouStockStatus(state, getters) {
    let status = 'out_of_stock';
    if (getters.getSkuObject.production_eta_days && getters.getSkuObject.production_eta_days > 0) {
      if (getters.isMadeForYouSkuInStock) status = 'in_stock';
      if (getters.isMadeForYouSkuLimitedInStock) status = 'limited_stock';
      if (getters.isMadeForYouSkuOutOfStock) status = 'out_of_stock';
    }
    return status;
  },
  isMadeForYouOrderATS(state, getters) {
    return getters.isMadeForYouGroup && (getters.isMadeForYouSkuInStock || getters.isMadeForYouSkuLimitedInStock);
  }
};

export {
  getters
};