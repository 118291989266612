import Vue from "vue";
import router from "~/router/index";

import RetailerAPI from "@API/retailer.js";

export default {
  namespaced: true,
  state: () => ({
    error: window.error,
    catalog_cfg: window.catalog_cfg,
    settings: window.settings,
    routePath: ""
  }),
  actions: {
    changeRoute({ commit }, payload) {
      if (!payload) return;
      commit('updateRoute', payload);
    },
  },
  mutations: {
    updateRoute(state, payload) {
      Vue.set(state, "routePath", payload);
    }
  },
  getters: {
    retailerAPI(state) {
      return new RetailerAPI(state.settings);
    }
  }
};
