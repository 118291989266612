import shares from "~/shared";

const getters = {
  // URL Discount support
  /**
   * Check if discount code is present in the URL
   * @param {Object} state
   * @returns {Boolean} is discount code in the URL
   */
  isDiscountInURL(state) {
    if (!state.options || !state.options.query || !state.options.query.discount_code) return false;
    return !!state.options.query["discount_code"];
  },
  /**
   * Discount code info based on the discount code in URL
   * @param {Object} state 
   * @param {Object} getters 
   * @returns {Object} discount code info
   */
  discountInURLInfo(state, getters) {
    if (!getters.isDiscountInURL) return false;
    return getters.productData.discount_info;
  },
  isDiscountInCatalog(state) {
    if (!state.options || !state.options.catalogs) return false;
    return state.options.catalogs && state.options.catalogs.indexOf('-') >= 0;
  },
  discountInfo(state, getters) {
    if (getters.isDiscountInURL && getters.discountInURLInfo) {
      return getters.discountInURLInfo;
    } else if (getters.isDiscountInCatalog && getters.productData && getters.productData.discount_info) {
      return getters.productData.discount_info;
    } else if (getters.productData && getters.productData.discount_info) {
      return getters.productData.discount_info;
    } else {
      return;
    }
  },
  isDiscountAvailable(state, getters) {
    if (getters.discountInfo) return true;
    if (!state.options.catalogs || !getters.productData.discount_info) return false;
    if (state.options.catalogs.indexOf('-') >= 0) return true;
    return false;
  },
  /**
   * Promo supports
   */
  isPercentDiscount(state, getters) {
    return getters.discountInfo.type === "percent" || getters.discountInfo.percent;
  },
  isAmountDiscount(state, getters) {
    return getters.discountInfo.type === "amount" || getters.discountInfo.coupon;
  },
  isFixedDiscount(state, getters) {
    return getters.discountInfo.type === "fixed" || getters.discountInfo.fixed_amount;
  },
  discountInfoAmount(state, getters) {
    if (!getters.isDiscountAvailable) return 0;
    if (getters.discountInfo.amount && getters.isAmountDiscount) return Number(getters.discountInfo.amount) || 0;
    if (getters.discountInfo.coupon && getters.isAmountDiscount) return Number(getters.discountInfo.coupon) || 0;
    return 0;
  },
  discountInfoPercentage(state, getters) {
    if (!getters.isDiscountAvailable) return;
    if (getters.discountInfo.percent && getters.isPercentDiscount) return Number(getters.discountInfo.percent);
    const percent = ((Number(getters.discountInfo.coupon)) / Number(getters.getSkuObject.regular_retail) * 100).toFixed(0);
    if (percent > 0) return percent;
    return 0;
  },
  discountInfoFixed(state, getters) {
    if (!getters.isDiscountAvailable) return;
    if (getters.discountInfo.fixed_amount && getters.isFixedDiscount) return Number(getters.discountInfo.fixed_amount);
    return 0;
  },
  discountedAmount(state, getters) {
    if (!getters.discountInfoAmount) return 0;
    if (getters.isMadeForYou) return Number(getters.getSkuObject.regular_retail) - getters.discountInfoAmount;
    if (getters.isReservePreorder) return Number(getters.getSkuObject.regular_retail) - getters.discountInfoAmount;
    if (getters.isPromoApplied && getters.isAmountDiscount) return Number(getters.getSkuObject.regular_retail) - getters.discountInfoAmount;
    if (getters.isPromoApplied && getters.isPercentDiscount) return getters.calculateDiscountedPercent
  },
  calculateDiscountedPercent(state, getters) {
    if (getters.isMadeForYou) return getters.discountInfoPercentage;
    if (getters.isReservePreorder) return getters.discountInfoPercentage;
    if (getters.isPromoApplied && getters.isPercentDiscount) return getters.getPromoDiscountPercentage;
    return shares.getPercentOff(getters.getSkuObject);
  }
};

export {
  getters
};
