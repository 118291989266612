import dayjs from "dayjs";

const dateFn = {
  formatDate(dateString) {
    return dayjs(dateString).format('MMMM D');
  },
  dayToPeriod(day) {
    const durations = [
      { duration: "days", days: 1, max: 7, gap: 2 },
      { duration: "weeks", days: 7, max: 56, gap: 2 },
      { duration: "months", days: 30, gap: 1 }
    ];
    let message;
    for (let index = 0; index < durations.length && !message; index++) {
      const el = durations[index];
      if (!el.max || (el.max && day <= el.max)) {
        let expected = Math.round(day / el.days) || 1;
        message = `${expected} to ${expected + el.gap} ${el.duration}`;
      }
    }
    return message;
  }
};

export default dateFn;