import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import groupFunctions from "~/shared/_groupFunctions.js";

// PDP modules
import * as defaultStore from '~/store/pdpModules/pdp.default.store.js';
import * as madeforyouStore from '~/store/pdpModules/pdp.madeforyou.store.js';
import * as preorderStore from '~/store/pdpModules/pdp.preorder.store.js';
import * as reservepreorderStore from '~/store/pdpModules/pdp.reservepreorder.store.js';
import * as promoStore from '~/store/pdpModules/pdp.promo.store.js';
import * as filterStore from '~/store/pdpModules/pdp.filter.store.js';
import * as videosStore from '~/store/pdpModules/pdp.video.store.js';

export default {
  namespaced: true,
  state: () => {
    return {
      catalog_cfg: window.catalog_cfg,
      settings: window.settings,
      location: window.location,
      products: [],
      options: {
        gender: '',
        size: '',
        slug: '',
        style: '',
        sku: '',
        color: '',
        categories: '',
        catalogs: '',
        query: {}
      }
    }
  },
  mutations: {
    addProduct(state, payload) {
      if (!payload || !payload.product) return;
      if (groupFunctions.findProduct(state.products, {
        slug: payload.product.slug,
        gender: payload.product.gender,
        style: payload.product.style_list && payload.product.style_list.length > 0 ? payload.product.style_list[0].data_style.toLowerCase() : undefined,
      })) {
        return;
      }
      state.products.push(payload);
    },
    updateProductSlug(state, payload) {
      state.product_slug = payload;
    },
    updateOptions(state, payload) {
      Vue.set(state, 'options', payload);
    },
    updateSku(state, payload) {
      if (!payload) {
        Vue.set(state.options, 'sku', undefined);
      } else {
        Vue.set(state.options, 'sku', payload);
      }
    },
    updateColor(state, payload) {
      if (!payload) {
        Vue.set(state.options, 'color', undefined);
      } else {
        let color_name = typeof payload === 'object' ? payload.data_color.toLowerCase() : payload;
        Vue.set(state.options, 'color', color_name);
      }
    },
    updateMode(state, payload) {
      if (!payload) Vue.set(state.mode, 'default');
      Vue.set(state, 'mode', payload);
    }
  },
  actions: {
    addProduct({ commit }, payload) {
      if (!payload) return;
      commit('addProduct', payload);
    },
    setOptions({ commit }, payload) {
      if (!payload) return;
      commit('updateOptions', payload);
    },
    setSku({ commit }, payload) {
      commit('updateSku', payload)
    },
    clearSku({ commit }) {
      commit('updateSku', undefined)
    },
    setColor({ commit }, payload) {
      commit('updateColor', payload)
    },
    clearColor({ commit }) {
      commit('updateColor', undefined)
    },
    updateSlug({ commit }, payload) {
      if (!payload) throw Error('Slug is not defined', payload);
      commit('updateProductSlug', payload);
    }
  },
  getters: {
    mode(state, getters) {
      /**
       * Flag to present different mode of PDP we are working with, list of available modes:
       * 'default': Regular PDP
       * 'reservepreorder': Reserve Preorder PDP
       * 'madeforyou': M4U PDP
       * 'preorder': Preorder PDP
      */
      const sku = getters.getSkuObject;
      if (!sku) return "default";

      // Mode override URLs
      if (getters.isPreorderURL) return "preorder";
      if (getters.isReservePreorderURL) return "reservepreorder";
      if (getters.isMadeForYouURL) return "madeforyou";

      if (getters.isCurrentATS || getters.isETAtoATS) return "default";
      if (getters.isPreorderATS) return "preorder";
      if (getters.isReserveATS) return "reservepreorder";
      if (getters.isMadeForYouATS) return "madeforyou";
      if (getters.isComingSoon) return "comingsoon";

      if (getters.isPreorderable) return "preorder";
      if (getters.isReservePreorderGroup) return "reservepreorder";
      if (getters.isMadeForYouGroup) return "madeforyou";
      // This statement is always true, product needs to be in webstoreCurrent to be on PLP and PDP
      if (getters.isCurrent) return "default";
      return "default";
    },
    getStockStatus(state, getters) {
      if (getters.isDefault) return getters.regularStockStatus;
      if (getters.isPreorder) return getters.preorderStockStatus;
      if (getters.isReservePreorder) return getters.reserveStockStatus;
      if (getters.isMadeForYou) return getters.madeForYouStockStatus;
      return "out_of_stock";
    },
    isDefault(state, getters) {
      return getters.mode === "default";
    },
    isPreorder(state, getters) {
      return getters.mode === "preorder"
    },
    isReservePreorder(state, getters) {
      return getters.mode === "reservepreorder"
    },
    isMadeForYou(state, getters) {
      return getters.mode === "madeforyou"
    },
    isComingSoon(state, getters) {
      if (!getters.getSkuObject) return false;
      return !!getters.getSkuObject?.catalog_tags?.find(catalog_tag => catalog_tag === 'webstoreComingSoon');
    },
    isAvailable(state, getters) {
      if (!getters.isSkuSelected) return;
      const getSizeObject = getters.getSizeObject;
      if (!getSizeObject) return;
      const availableSizes = getters.availableSizes;
      if (!availableSizes) return false;
      return !!availableSizes.find(available_size => {
        if (getSizeObject.label) {
          return getSizeObject.label.toString().toLowerCase() === available_size.label.toString().toLowerCase();
        } else if (getSizeObject.mens) {
          return Number(getSizeObject.mens) === Number(available_size.mens);
        }
      });
    },
    availableSizes(state, getters) {
      if (!getters.isSkuSelected) return false;
      const color = getters.getColorObject;
      const productData = getters.productData;
      if (!productData) return false;
      return color && !isEmpty(color) && color.available_size_list
        ? color.available_size_list
        : productData.product.available_size_list;
    },
    /*
      Product related data
    */
    productData(state) {
      const products = groupFunctions.findProduct(state.products, state.options);
      return products;
    },
    product(state, getters) {
      if (!getters.productData) return;
      return getters.productData.product
    },
    alsoLikeProducts(state, getters) {
      if (!getters.productData) return;
      return getters.productData.also_like_products;
    },
    review(state, getters) {
      if (!getters.productData) return;
      return getters.productData.review;
    },
    isProductExternal(state, getters) {
      if (!getters.product) return;
      const EXTERNAL_SHOP = ["webstoreOrthoticShop", "webstoreFootCareMax"];
      return !!getters.product.catalog_tags.find(catalog => EXTERNAL_SHOP.includes(catalog));
    },
    productConfig(state, getters) {
      if (!getters.productData) return;
      return {
        color: getters.productData.color,
        style: getters.productData.style,
        size: getters.productData.size,
        category: getters.productData.category,
        gender: getters.productData.gender,
        available_in_other_gender: getters.productData.available_in_other_gender
      }
    },
    gender(state, getters) {
      if (!getters.productData) return;
      return getters.productData.gender || state.options.gender;
    },
    slug(state, getters) {
      if (!getters.product) return;
      return getters.product.slug;
    },
    /*
      Get Sku Object and Helpers
    */
    getSkuObject(state, getters) {
      if (state.options?.sku) {
        return state.options.sku;
      } else if (getters._getSkuObjectUsingSkuName) {
        return getters._getSkuObjectUsingSkuName; // Get Sku Object by sku name
      } else if (state.options?.size && getters._getSkuObjectUsingSizeObj) {
        return getters._getSkuObjectUsingSizeObj; // Get Sku Object by using size
      } else if (state.options.color && getters._getSkuObjectUsingColor) {
        return getters._getSkuObjectUsingColor; // Get Sku Object by using color
      } else {
        return getters.product.default_sku || getters.product.sku_list[0];
      }
    },
    _getSkuObjectUsingSkuName(state, getters) {
      if (state.options?.sku) return state.options.sku;
      if (!getters.productData?.product || !state.options?.sku) return;
      const sku = state.options.sku;
      return getters.productData.product.sku_list.find(skuObj => skuObj.sku === sku.sku);
    },
    _getSkuObjectUsingSizeObj(state, getters) {
      if (!getters.product) return;
      const sizeObject = getters.getSizeObject;
      if (!sizeObject) return;
      const options = state.options;
      let skuList = getters.product.sku_list;
      if (options.color) {
        skuList = skuList.filter(skuObj => skuObj.data_color.toLowerCase() === options.color.toLowerCase());
      }
      return skuList.find(skuObj => {
        if (skuObj.sizes.number && sizeObject.number) {
          return Number(skuObj.sizes.number) === Number(sizeObject.number);
        } else if (skuObj?.sizes[options.gender || 'mens'] && sizeObject?.[options.gender || 'mens']) {
          if (Number(skuObj?.sizes[options.gender || 'mens']) && Number(sizeObject?.[options.gender || 'mens'])) {
            return Number(skuObj.sizes[options.gender || 'mens']) === Number(sizeObject[options.gender || 'mens']);
          } else {
            return skuObj.number.toLowerCase() === sizeObject.number.toLowerCase(); // support for socks sizes (S, M, L)
          }
        } else if (skuObj.number && sizeObject.number) {
          return Number(skuObj.number) === Number(sizeObject.number); // support for half sizes
        } else {
          return;
        }
      });
    },
    _getSkuObjectUsingColor(state, getters) {
      if (!getters.product) return;
      const color = getters.product.color_list.find(colorObj => colorObj.data_color.toLowerCase() === state.options.color.toLowerCase());
      if (!color) return;
      const options = state.options;
      let skuList = getters.product.sku_list;
      if (options.size) {
        const sizeObject = getters.getSizeObject;
        if (sizeObject) {
          skuList = skuList.filter(skuObj => skuObj.sizes.number === sizeObject.number);
        }
      }
      return skuList.find(skuObj => skuObj.data_color.toLowerCase() === color.data_color.toLowerCase());
    },
    isSkuSelected(state, getters) {
      const sku = state.options?.sku;
      return sku && !isEmpty(sku);
    },
    /*
      Get Size Object and Helpers
    */
    getSizeObject(state, getters) {
      if (!getters.product) return;
      const options = state.options;
      if (state.options?.sku?.sizes) return state.options.sku.sizes;
      if (options.size) {
        if (options.size.toString().match(/^[0-9.]{1,4}$/g)) {
          return getters._getSingleSizeObject;
        } else if (options.size.toString().match(/^[x]{1}[sl]{1}|[sml]{1}/g)) {
          return getters._getSizeRangeObject;
        }
      } else if (getters.product.default_sku && getters.product.default_sku.number && Number(getters.product.default_sku.number) && getters.isComingSoon && !getters.isPreorderable) {
        return Number(getters.product.default_sku.number);
      } else if (getters.product.size_list && getters.product.size_list.length === 0) {
        return;
      } else {
        return;
      }
    },
    isHalfSize(state, getters) {
      return !!getters.product.size_list.find(sizeObj => sizeObj.number && sizeObj.number.toString().match(/[.]{1}/g));
    },
    _getSingleSizeObject(state, getters) {
      const options = state.options;
      return getters.product.size_list.find(sizeObj => {
        if (getters.isHalfSize) {
          return Number(sizeObj.number) === Number(options.size)
        } else {
          return Number(sizeObj[options.gender || 'mens']) === Number(options.size)
        }
      });
    },
    _getSizeRangeObject(state, getters) {
      return getters.product.size_list.find(sizeObj => sizeObj.label.toString().toLowerCase() === state.options.size.toLowerCase());
    },
    /*
      Get Color Object and Helpers
    */
    getColorObject(state, getters) {
      if (!getters.productData || !getters.product) return;
      if (!getters.product.color_list || getters.product.color_list.length === 0) return;
      const options = state.options;
      if (!options.color) {
        return getters.product.color_list.find(colorObj => colorObj.data_color.toLowerCase() === getters.getSkuObject.data_color.toLowerCase());
      } else {
        return getters.product.color_list.find(colorObj => colorObj.data_color.toLowerCase() === options.color.toLowerCase());
      }
    },
    co2negMediaURL(state, getters) {
      const co2negMedia = getters.product.media_list.find(media => media.co2neg);
      if (co2negMedia) return co2negMedia.co2neg;
    },
    co2negIconBlack(state, getters) {
      return getters.co2negMediaURL.find(record => record.name === 'co2neg-black');
    },
    co2negIconWhite(state, getters) {
      return getters.co2negMediaURL.find(record => record.name === 'co2neg-white');
    },
    getSku(state, getters) {
      return getters.getSkuObject;
    },
    regularPrice(state, getters) {
      return Number(getters.product.retail_price_list[0].regular);
    },

    /**
     * Custom support for different mode of the store
     */
    ...madeforyouStore.getters,
    ...reservepreorderStore.getters,
    ...preorderStore.getters,
    ...defaultStore.getters,
    /**
     * Custom store support functionalites
     */
    ...promoStore.getters,
    ...filterStore.getters,
    ...videosStore.getters,
  }
};
