const getters = {
  activePopupVideo(state) {
    if (!state.options.hash) return;
    const popupVideoList = state.filtersListData.plp_popup_video_list;
    if (!popupVideoList || !Array.isArray(popupVideoList) || !popupVideoList.length === 0) return;
    return popupVideoList.find(video => video.icon === state.options.hash);
  }
}

export {
  getters
};