export default {
  path: `:gender(mens|womens)?/
    :categories(footbeds|footwear|socks|recork)/
    :catalogs(clearance|deals|preorder-[A-z0-9\-]+|preorder|madeforyou-[A-z0-9\-]+|madeforyou|reserve-[A-z0-9\-]+|reserve)?/
    :product_slug([a-z\-]+)/
    :product_style([a-z\-]{3,})?/
    :product_color([a-z\-]{3,})?/
    :product_size([0-9\.]{1,4}|[m]{1}|[x]{0,1}[sl]{1})?`.replace(/\s/g, ''),
  name: 'pdp',
  component: () => import(/* webpackChunkName: "pdp-view" */ '~/pages/Shopping/PDP.vue'),
};
