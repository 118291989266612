export default {
  path: `/${window.settings.region}/retailer`,
  name: 'retailer',
  component: () => import(/* webpackChunkName: "account-view" */ '~/views/Retailer.vue'),
  children: [
    {
      path: `login`,
      name: "retailer_login",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Retailer/RetailerLogIn.vue")
    },
    {
      path: `request-password-reset`,
      name: "retailer_request_reset_password",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Retailer/RetailerLogIn.vue")
    }
  ]
};
