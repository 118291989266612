const getters = {
  // Preorder support
  isPreorderURL(state, getters) {
    if (!state.options.catalogs) return false;
    if (!getters.product.catalog_tags.find(catalog_tag => catalog_tag === 'webstorePreorder')) return;
    if (state.options.catalogs) return state.options.catalogs.indexOf('preorder') >= 0;
    return state.options.catalogs.indexOf('preorder') >= 0;
  },
  isPreorderable(state, getters) {
    return getters.getSkuObject?.catalog_tags?.includes('webstorePublicPreorder') || getters.getSkuObject?.catalog_tags?.includes('webstorePreorder')
  },
  isPreorderSkuOutOfStock(state, getters) {
    return getters.isPreorderable && getters.getSkuObject && !getters.getSkuObject.webstore_preorder_ats;
  },
  isPreorderSkuInStock(state, getters) {
    return getters.isPreorderable && getters.getSkuObject && getters.getSkuObject.webstore_preorder_ats > 0;
  },
  isPreorderSkuLimitedInStock(state, getters) {
    return getters.isPreorderable && getters.getSkuObject && getters.getSkuObject.webstore_preorder_ats <= 5 && getters.getSkuObject.webstore_preorder_ats > 0;
  },
  preorderStockStatus(state, getters) {
    let status = 'out_of_stock';
    if (getters.getSkuObject.confirmed_eta_days && getters.getSkuObject.confirmed_eta_days > 0) {
      if (getters.isPreorderSkuInStock) status = 'in_stock';
      if (getters.isPreorderSkuLimitedInStock) status = 'limited_stock';
      if (getters.isPreorderSkuOutOfStock) status = 'out_of_stock';
    }
    return status;
  },
  isPreorderATS(state, getters) {
    return getters.isPreorderable && (getters.isPreorderSkuInStock || getters.isPreorderSkuLimitedInStock);
  }
};

export {
  getters
};