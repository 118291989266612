export default {
  path: `/${window.settings.region}/reviews`,
  component: () => import(/* webpackChunkName: "app-view" */ "~/views/Review.vue"),
  children: [
    {
      path: `/`,
      name: "reviews",
      redirect: "all"
    },
    {
      path: "(tag|search|product)",
      name: "reviews_empty_search_criteria",
      redirect: "all"
    },
    {
      path: `:search_parameter(all|product|tag)/:search_term([a-z-]+)?/:page([0-9]{1,3})?`,
      name: "RLP",
      component: () => import(/* webpackChunkName: "rlp-view" */ "~/pages/Review/RLP.vue")
    },
    {
      path: `:search_parameter(search)/:page([0-9]{1,3})?`,
      name: "RLP_search",
      component: () => import(/* webpackChunkName: "rlp-view" */ "~/pages/Review/RLP.vue")
    },
    {
      path: `id/:review_id([0-9]+)`,
      name: "reviews_id",
      component: () =>
        import(/* webpackChunkName: "reviews-view" */ "~/pages/Review/SingleReviewDisplay.vue")
    },
    {
      path: `submit-review`,
      name: "reviews_submit",
      component: () =>
        import(/* webpackChunkName: "reviews-submit" */ "~/pages/Review/ReviewSubmit.vue")
    },
    {
      path: `completed-review`,
      name: "reviews_completed",
      component: () =>
        import(/* webpackChunkName: "reviews-submit" */ "~/pages/Review/ReviewCompleted.vue")
    }
  ]
};
