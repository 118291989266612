import axios from "axios";

const loading = document.querySelector("#loading");

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
  // trigger 'loading=true' event here
  if (loading) loading.style.display = "block";
  return config;
}, (error) => {
  // trigger 'loading=false' event here
  if (loading) loading.style.display = "none";
  return Promise.reject(error);
});

axios.interceptors.response.use((config) => {
  // trigger 'loading=true' event here
  if (loading) loading.style.display = "none";
  return config;
}, (error) => {
  // trigger 'loading=false' event here
  if (loading) loading.style.display = "none";
  return Promise.reject(error);
});

export default axios;