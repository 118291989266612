export default {
  matchHalfSizeToFullSize(input, compareTo) {
    let n_input = Number(input);
    let n_compareTo = Number(compareTo);
    if (n_input % 1 == 0) {
      return n_input === n_compareTo;
    } else {
      return n_input === n_compareTo || (n_input + 0.5) === n_compareTo;
    }
  },
  getSizeLabel(size) {
    return `M${size.mens} / W${size.womens}`;
  }
}