export default {
  path: `/${window.settings.region}/account`,
  name: 'account',
  component: () => import(/* webpackChunkName: "account-view" */ '~/views/Account.vue'),
  children: [
    {
      path: `login`,
      name: "account_login",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Account/AccountLogIn.vue")
    },
    {
      path: `request-password-reset`,
      name: "account_request_reset_password",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Account/AccountLogIn.vue")
    },
    {
      path: `reset-password`,
      name: "account_reset_password",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Account/AccountLogIn.vue")
    },
    {
      path: `create`,
      name: "account_create",
      component: () =>
        import(/* webpackChunkName: "account-login-view" */ "~/pages/Account/AccountLogIn.vue")
    },
    {
      path: `verify`,
      name: "account_verify",
      component: () =>
        import(/* webpackChunkName: "account-verify-view" */ "~/pages/Account/AccountLogIn.vue")
    }
  ]
};